body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* The image used */
  background-image: url("bg.png");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  height: 100%;
  background-color: black;
}

@font-face {
  font-family: "Pixels";
  src: local("Pixels"), url(./components/media/Pixels.woff) format("woff");
  line-height: "1.5";
}
