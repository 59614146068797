@font-face {
  font-family: "Pixels";
  src: local("Pixels"), url(./components/media/Pixels.woff) format("woff");
  line-height: "1.5";
}

html {
  font-family: "Pixels" !important;
  font-weight: 400;
}

body {
  font-family: "Pixels" !important;
  /* background-Pixels: #fef8f7; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal-60w {
  max-width: 50% !important;
  border-radius: 40px;
}

.modal-40w {
  max-width: 40% !important;
  border-radius: 40px;
}

.modal-30w {
  max-width: 30% !important;
  border-radius: 40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mint-body {
  font-family: "Pixels";
}

.active-gradient-selector {
  background: linear-gradient(
    270deg,
    #ffdaff,
    #e4ffd8,
    #d8fbff,
    #ffc5c5,
    #fffec5,
    #ffffff
  );
  background-size: 1200% 1200%;

  -webkit-animation: ActivateGradient 30s ease infinite;
  -moz-animation: ActivateGradient 30s ease infinite;
  -o-animation: ActivateGradient 30s ease infinite;
  animation: ActivateGradient 30s ease infinite;
}

@-webkit-keyframes ActivateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes ActivateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes ActivateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes ActivateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.lighter-active-gradient {
  background: linear-gradient(
    270deg,
    #f0fffb,
    #fff0f0,
    #fcfff0,
    #f0f2ff,
    #fef0ff,
    #ffffff,
    #ffffff
  );
  background-size: 1400% 1400%;

  -webkit-animation: LighterActiveGradient 30s ease infinite;
  -moz-animation: LighterActiveGradient 30s ease infinite;
  -o-animation: LighterActiveGradient 30s ease infinite;
  animation: LighterActiveGradient 30s ease infinite;
}

@-webkit-keyframes LighterActiveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes LighterActiveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes LighterActiveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes LighterActiveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
